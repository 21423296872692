const MESSAGES = {
  MSG_E_001: "サーバーに接続できません。管理者にご連絡してください。",
  MSG_E_002: "ユーザーID・パスワードが間違っています。",
  MSG_E_003: (field) => `${field}に値を入力してください。`,
  MSG_E_004: (first_length, second_length) =>
    `${first_length}文字以上${second_length}文字以内で入力してください。`,
  MSG_E_005: "文字タイプを４種以上使用してください。",
  MSG_E_006: "入力されたパスワードが一致しませんでした。",
  MSG_E_007: "パスワードが間違っています。",
  MSG_I_008: "パスワードが変更されました。",
  MSG_W_009: "選択中のユーザーIDを削除しますか。",
  MSG_I_010: (userId) => `${userId}を正常に削除しました。`,
  MSG_E_011: "[○○]はすでに削除されています。",
  MSG_E_012: "ユーザーIDが重複しています。",
  MSG_I_013: (userId) => `${userId}を登録しました。`,
  MSG_I_014: (userId) => `${userId}を編集しました。`,
  MSG_I_015: "データがありません。",
  MSG_E_016: "新しいパスワードが現在のパスワードと同じなので、変更できません。",
  MSG_E_017: "アップロードファイルを選択してください。",
  MSG_E_018: "取引コードが重複しています。",
  MSG_I_019: "ファイルのアップロードが完了しました。",
  MSG_I_020: "パスワードがリセットされました。",
  MSG_E_021: "削除したいユーザーIDを選択してください。",
  MSG_W_022: "ユーザーIDのパスワードをリセットしますか。",
  MSG_I_023: "データが正常に更新されました。",
  MSG_E_024: "データの更新に失敗しました。",
  MSG_E_025: "削除したいフォルダーIDを選択します。",
  MSG_I_026: (folderID) => `${folderID}を正常に削除しました。`,
  MSG_I_027: `フォルダが正常に作成されました`,
  MSG_E_028: `フォルダーの作成に失敗しました`,
  MSG_I_029: `フォルダーの作成に成功しました`,
  MSG_E_023: "削除したい取引コードを選択してください。",
  MSG_I_024: "正常に削除されたトランザクション コード",
  MSG_LIST_E_024: "PDFファイル以外他のファイルが選択できません。",
  MSG_E_025: "スペースのみ入力しないでください。",
  MSG_E_026: "フォルダー名に「%」「/」「\\」などの特殊文字は使用できません。",
  MSG_E_027: "ファイルサイズが大きすぎます、サイズ調整して再度お試しください。",
  MSG_E_034: "認証に失敗しました。",
  MSG_E_035: "リクエストは期限切れです。",
  MSG_E_036: "この機能にアクセス権限がありません。",
  MSG_E_TOKEN_INVALID:
    "アクセストークンが無効になってしまって再度ログインしてください。",
};

export default MESSAGES;
