import HTTP_STATUS_CONTSTANTS from "./constant/httpStatus";
import MESSAGES from "./constant/messages";
import { configs } from "./index.js";
import axios from "axios";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import { store } from "store";
import * as actionType from "store/actions";

const api = axios.create({
  baseURL: configs.REACT_APP_BACKEND_URL,
  withCredentials: false,
});

api.interceptors.request.use((config) => {
  store.dispatch({ type: actionType.LOADING_SPIN, payload: { loading: true } });
  const token = Cookies.get("access_token");
  config.headers.Authorization = "Bearer " + token;
  config.rawData = config.data;
  return config;
});

api.interceptors.response.use(
  (response) => {
    store.dispatch({
      type: actionType.LOADING_SPIN,
      payload: { loading: false },
    });
    return response.data;
  },
  (error) => {
    store.dispatch({
      type: actionType.LOADING_SPIN,
      payload: { loading: false },
    });
    if (error?.response?.status === HTTP_STATUS_CONTSTANTS.ERROR_CODE_401) {
      Cookies.remove("access_token");
      Cookies.remove("userId");
      Cookies.remove("roles");
      <Navigate to="/login" />;
      return Promise.reject({ message: MESSAGES.MSG_E_TOKEN_INVALID });
    }
    if (error?.response?.status === HTTP_STATUS_CONTSTANTS.ERROR_CODE_404) {
      // Router.push(APP_ROUTES.PAGE_404);
    }
    return Promise.reject(
      error?.response?.data ?? { message: MESSAGES.MSG_E_001 }
    );
  }
);

export { api };
