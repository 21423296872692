import { message } from "antd";
import Loader from "components/Loader/Loader";
import { Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./styles/App.scss";

import { Spin } from "antd";
import Header from "components/Header/Header";
import { useSelector } from 'react-redux';
import ProtectedRoute from "./pages/ProtectedRouter";
import routes from "./pages/routes";

message.config({
  maxCount: 1
});

function App() {
  const utils = useSelector((state) => state.utils);

  return (
    <Spin spinning={utils.loading} size="large">
      <Router>
        <Suspense fallback={<Loader />}>
          <Routes>
            {routes.map(({ element, path, ...rest }) => {
              if (path === "/login")
                return (
                  <Route element={element} key={path} path={path} {...rest} />
                );
              else
                return (
                  <Route
                    element={
                      <div className="h-[100vh] bg-[#faf0e666]">
                        <Header />
                        <ProtectedRoute children={element} />
                      </div>
                    }
                    key={path}
                    path={path}
                    {...rest}
                  />
                );
            })}
          </Routes>
        </Suspense>
      </Router>
    </Spin>
  );
}

export default App;
